#bar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    padding-top: 10px;
    width: calc(100% - 200px);
    transition-duration: 0.5s;
    z-index: 20;
}

.barColored {
    background-color: #f1f1f1dc;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
    transition-duration: 0.5s;
}

#menuIcon {
    height: 30px;
    margin-right: 10px;
    display: none;
}

#logoArea {
    display: flex;
    align-items: center ;
    justify-content: center;
    letter-spacing: 2px;
    font-size: 16px;
    color: white;
    font-weight: bold;
}

.logoAreaFontColor {
    color: black !important;
}

#barLogo {
    height: 50px;
}

#barLogo {
    margin-right: 20px;
}

#menu {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

#menu div {
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
}

#menu div:hover {
    background-color: aliceblue;
    border-bottom: 1px solid rgb(255, 0, 85);
    transition-duration: 0.5s;
}

@media screen and (max-width: 1000px) { 
    #bar {
        padding: 10px 50px;
        width: 100%;
    }

    #menu {
        display: none;
    }
    #menuIcon {
        display: block;
    }
}