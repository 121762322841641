#app {
    background-color: #f2fce6;
}

#backgroundImg {
    background: url(../img/background.jpg) center no-repeat;
    background-size: cover;
    color: #ffff;
    height: 600px;
}

#blogs {
    text-align: center;
    margin-top: 20px;
}

#blogsTitle {
    font-size: 30px;
}

#redTitle {
    font-size: 32px;
    font-weight: bold;
    color: rgb(255, 0, 85);
}

#redTitle div {
    margin-bottom: 10px;
    /* background-color: red; */

}

#border {
    width: 50px;
    height: 3px;
    background-color: rgb(255, 0, 85);
    margin: auto;
}

#Effectieve {
    font-size: 17px;
    margin: auto;
}

.slide-container {
    padding: 0 50px;
    margin: auto;
    text-align: center;
    margin-top: 50px;
    background-color: #f1f1f1;
}

.textSlide {
    width: 90%;
    margin: auto;
}

.slideTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
}

.titleBotmBorder {
    height: 2px;
    width: 70px;
    background-color: #5798ff;
    margin: auto;
    margin-bottom: 30px;
}

.slideText {
    line-height: 35px;
    font-size: 20px;
}

.slideName {
    margin-top: 30px;
    font-size: 22px;
}

#youtubeSec, #spotifySec {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 30px;
}

#spotifySec {
    margin-top: -49px;
}

#aboutVid ,#aboutSong {
    background-color: #fe4365;
    width: 350px;
    padding: 40px 50px;
}

#youtubeTitle, #question {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 30px;
}

.day {
    padding: 10px 0;
    border-bottom: 0.5px solid #ffaaba;
    width: 90%;
}

#plan {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 30px;
}

#plan img {
    margin-right: 5px;
}

#email:hover, #plan:hover {
    color: black;
    transition-duration: 0.5s;
    cursor: pointer;
}

#youtubeVid {
    width: 100%;
    height: 550px;
}

/*------------------Bottombar-------------------*/
#bottomBar {
    background-color: #46b68b;
    color: white;
    font-size: 15px;
    width: 100%;
    margin-top: 30px;
    overflow-x: auto;
}

#top {
    display: flex;
    justify-content: center;
}

.kauaa {
    margin: 30px 70px;
}

.kauaa div {
    padding: 5px 0px;
}

.heading {
    font-size: 18px;
    font-weight: bold;
}

#amazon {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

#amazon img {
    margin-top: 10px;
    padding-right: 70px;
}

#amazon select {
    height: 30px;
    width: 120px;
    color: white;
    background-color: inherit;
    border-radius: 3px;
}

#language {
    padding: 10px 200px;
    text-align: center;
    font-size: 12px;
}

#language span {
    padding: 0px 8px;
}

#bottomBar3 {
    background-color: #131A22;
    color: white;
    font-size: 12px;
}

#services {
    display: flex;
    align-items: center;
    justify-content: center;
}

#services div {
    padding: 0px 40px;
}

#copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fe4365;
    padding: 30px 20px 50px 20px;
    margin: 0 50px;
}

#navBarMenu {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 900px) { 
    #youtubeSec, #spotifySec {
        flex-direction: column;
    }

    #aboutVid ,#aboutSong {
        width: 80%;
    }

    #youtubeVid, #spotify {
        width: 100% !important;
        height: 300px;
    }

    #aboutVid, #aboutSong {
        padding: 40px 32px;
    }
}

@media screen and (max-width: 800px) {
    #top {
        display: unset;
        justify-content: unset;
        font-size: 12px;
    }  
    
    #language {
        padding: 10px 10px;
    }  

    .slideText {
      font-size: 15px !important;
    }
}