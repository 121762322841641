#BlogBar {
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
    margin-top: 100px;
}

.BlogItem {
    background-color: rgb(219, 215, 215);
    margin: 0 10px;
    width: fit-content;
    width: 300px;
    overflow: hidden;
    padding-bottom: 30px;
}

.blogImg {
    /* width: 300px; */
  
    width: 100%;
    margin-bottom: 20px;
}

.blogImg:hover {
    transform: scale(1.10);
    transition-duration: 0.8s;
}

.title {
    margin-bottom: 10px;
}

.ques {
    color: rgb(255, 0, 85);
    font-size: 20px;
    margin-bottom: 20px;
}

.date {
    color: #363636;
}

.botmBorder {
    width: 40px;
    height: 3px;
    background-color: #363636;
    margin: auto;
    margin-top: 10px;
}

.moreInfo {
    background: #83af9b;
    width: fit-content;
    margin: auto;
    margin-top: 50px;
    padding: 10px 20px;
    border-radius: 50px;
}

.moreInfo:hover {
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
}

@media screen and (max-width: 900px) { 
    #BlogBar {
        flex-direction: column;
    }

    .BlogItem {
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
    }
}